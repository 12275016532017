<template>
	<div
		class="mt-30 text-left flex-row"
	>
		<CartelSetting
			:user="user"

			class="flex-side mr-30"
		></CartelSetting>

		<div class="flex-1 bg-white pa-20 radius-20">

			<div class="under-line-dick pb-10 flex-row">
				<h3 class="flex-1">멤버보기</h3>
			</div>
			<div class="mt-30">
				<div class="box radius-20 pa-10-20 flex-row items-center">
					<input
						v-model="item_search.search_value"
						type="text" placeholder="멤버 검색"
						maxlength="20"
						class="flex-1"
						@keyup.enter="getData"
					>
					<v-icon
						v-if="item_search.search_value"
						@click="item_search.search_value = ''; getData()"
						class="mr-10"
					>mdi-close-circle</v-icon>
					<v-icon
						@click="getData"
						type="submit" name="click"
					>mdi-magnify</v-icon>
				</div>

				<div class="mt-30">
					<h3 class="hide">멤버 검색 결과</h3>
					<ul
						v-if="invite_member.length > 0"
					>
						<li
							v-for="(item, index) in invite_member"
							:key="'item_' + index"
							@click="on098(item)"
							class="inline-block width-50 mb-20"
						>
							<div class="flex-row items-center">
								<div class="square-64 overflow-hidden radius-100 mr-10">
									<img
										:src="$request.upload_url(item.profle_nft_card_img_url)"
										@error="$bus.$emit('onErrorProfileImage', $event)"
										class="width-100 "
									>
								</div>
								<div class="flex-1">
									<strong>{{ item.nickname }}</strong>
									<p class="color-gray">
										{{  item.cartl_member_grade_name }}
									</p>
								</div>
							</div>
						</li>
					</ul>
					<div
						v-else
						class="none"
					>{{ $language.common.no_search_data }}</div>
				</div>
			</div>
		</div>

		<mafia098
			v-if="is_098"
			:cartel="item_cartel"
			:user="user"
			:friend="item_user"

			@cancel="is_098 = false"
		></mafia098>
	</div>
</template>

<script>
import mafia098 from "@/view/Cartel/mafia098";
import CartelSetting from "@/view/Cartel/CartelSetting";
export default {
	name: 'mafia082'
	, props: ['user']
	, components: {CartelSetting, mafia098},
	data: function(){
		return {
			program: {
				name: '멤버보기'
				, title: '멤버보기'
				, type: 'cartel_sub'
				, not_header: true
				, not_footer: true
				, bg_contents: 'bg-gray01'
			}
			, items_member: []
			, item_cartel: {}
			, item_search: this.$storage.init({
				search_value: ''
				, page_number: this.$language.base.page_number
				, pagerecnum: this.$language.base.pagerecnum
			})
			, is_098 : false
			, item_user: {}
		}
	}
	, computed: {
		invite_member: function(){
			let self = this
			console.log(self.item_cartel.user)
			return this.items_member.filter(function(item_member){

				return item_member
			})
		}
	}
	, methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_member_search
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, srchtext: this.item_search.search_value
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_member = result.data.cartl_member_list
					this.$storage.setQuery(this.item_search)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_search = true
				this.$bus.$emit('on', false)
			}
		}
		,getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postInvite: async function(member_id){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_invite
					, data: {
						cartel_id: this.item_cartel.id
						, member_id: member_id
					}
					, name: 'postInvite'
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: '초대요청이 완료되었습니다'})
					await this.getCartel()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, on098: function(user){
			console.log('user', user)
			this.is_098 = true
			this.item_user = user
		}
	}
	,async created() {
		this.$bus.$emit('onLoad', this.program)
		await this.getCartel()
		await this.getData()
	}

}
</script>